import React, { useRef } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import useStore from '../store/useStore'
import ContentHeader from '../components/Typography/ContentHeader'
import ContentText from '../components/Typography/ContentText'
import InnerLink from '../components/Typography/InnerLink'
import MediumImage from '../components/Image/MediumImage'
// import SectionStyle1 from '../components/curves/SectionStyle1'
import SEO from '../layouts/Seo'
import ImgWrapper from '../components/Image/ImgWrapper'
import ImgLightBox from '../components/lightbox/ImgLightBox'
import GeneralCarousel from '../contents/carousel/GeneralCarousel'
import KareGozluTablo from '../contents/tables/KareGozluTablo'
import DikdortgenGozluTablo from '../contents/tables/DikdortgenGozluTablo'
import OlcuAlert from '../contents/alert/OlcuAlert'
import HasirTelKullanim from '../contents/common/HasirtelKullanim'
const GalvanizCelikHasirPage = () => {
  const { getImageByName } = useStore()
  const lightBoxRef = useRef()
  return (
    <>
      <SEO
        title='Galvaniz Çelik Hasır Tel İmalatı - Erez Kaynak'
        description='Firmamız sıcak daldırma galvaniz , elektro galvaniz yada siyah ham çelik tel malzeme kullanarak punta kaynaklı çit hasır tel imalatı yapmaktadır. Firmamız çit hasır tellerini boyasız olarak imalatını yapmaktadır. Tel kalınlığı 2mm den 8mm tel kalınlığına kadar çit hasır tel imalatını yapabiliyoruz.'
        keywords='çit hasırı,çit teli,tel çit,bahçe çiti,Çit Hasır Teli İmalatı,çit hasır tel,yeşil bahçe çiti,batçe çit teli,panel çit,bahçe teli,tel çit fiyatları,bahçe çiti fiyatları,kafes teli,çit teli fiyatları,çit hasır tel fiyatları,çit hasır telleri kullanım alanları,çit hasarı istanbul,çit fens teli,fens teli istanbul'
        url='galvaniz-celik-hasir'
        imgurl={getImageByName('galvanizhasirtel9').sm.src}
      />
      <ImgLightBox ref={lightBoxRef} gallery={[getImageByName('galvanizhasirtel9').original.src]} />
      <Box paddingX={3} paddingY={5}>
        <Grid container spacing={6} alignItems='flex-start'>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
            <ContentHeader title='Galvaniz Çelik Hasır' description='Türkiyenin her yerine gönderim yapılmaktadır.' />
            <ContentText>
              <p>
                Galvaniz çelik hasırlar <strong>sıcak daldırma</strong> ya da <strong>galvaniz telden</strong> üretilmektedir.
              </p>

              <p>
                Tel kalınlığı 2mm'den 8mm tel kalınlığına kadar <strong>galvaniz çelik hasır</strong> imalatı yapabilmekteyiz.
              </p>
              <p>
                Göz aralığı standart olarak merkezden merkeze 15mm, 20mm, 25mm ve katlarında <strong>galvaniz çelik hasır</strong> imalatı
                yapabilmekteyiz. Örnek olarak ; 15mm katı olan 15x90mm veya 25mm katı olan 50x125mm gibi istediğiniz ölçüde{' '}
                <strong>çelik hasır</strong> yapabilmekteyiz. Özel göz aralıklarında (örn; 26,3x37,5mm vb.) yeni kalıp yaparak hızlı bir
                şekilde müşterilerimizin ihtiyacı olan <strong>galvaniz çelik hasırı</strong> uygun bir maaliyet ile üretiyoruz.
              </p>
              <p>
                <strong>Ebat</strong> olarak standart kalıplarımızda en büyük ölçü 2200x3000mm'dir.
              </p>
              <p>
                Standart kalıplarımız dışında istenilen boy ve ebatta <strong>galvaniz çelik hasır</strong> imalatı yapılmaktadır.
              </p>
              <p>
                İstenildiği takdirde kenarlardaki çıkıntıları keserek, kareden kareye tam ölçüde <strong>galvaniz hasır imalatı</strong>{' '}
                gerçekleştirebiliriz.
              </p>
              <p>Detaylı bilgi yada fiyat teklifi almak için bizi arayınız.</p>
            </ContentText>
            <Box textAlign='center' marginTop={5}>
              <InnerLink title='İletişim Bilgilerimiz İçin Tıklayınız' url='/iletisim' />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
            <ImgWrapper onClick={() => lightBoxRef.current.openLightBox()}>
              <MediumImage lazyHeight={500} img={getImageByName('galvanizhasirtel9')} alt='Özel hasır tel imalatı' />
            </ImgWrapper>
            <Box mt={2}>
              <MediumImage lazyHeight={500} img={getImageByName('celikhasirolculeri')} alt='özel göz hasır tel imalatı erez kaynak' />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <GeneralCarousel />
      <Box>
        <HasirTelKullanim title='Galvaniz  Çelik Hasır Kullanım Alanları' />
      </Box>
      <Box p={3}>
        <OlcuAlert />
      </Box>
      <Box p={3} mb={5}>
        <KareGozluTablo />
      </Box>
      <Box p={3} mb={5}>
        <DikdortgenGozluTablo />
      </Box>
    </>
  )
}

export default GalvanizCelikHasirPage
